import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Link from 'next/link';

import styles from './styles.module.scss';

import { useSession } from '@utils/context/session';
import {
  SegmentEventNamesEnum,
  SegmentEventPagesEnum,
  useAnalytics,
} from '@utils/hooks/useAnalytics';
import { colorsByTheme } from '@utils/constants/colors';
import { Contacts } from '@utils/constants/global';
import { Cross, ChevronDown, Cellphone, Envelope } from '@assets/icons';
import { useToggleBodyScroll } from '@utils/hooks/toggleBodyScroll';

type Props = {
  browseRentalsDropdown: JSX.Element;
  profileDropdown: JSX.Element;
  onClose: () => void;
};

const BurgerMenu = (props: Props) => {
  const { browseRentalsDropdown, profileDropdown, onClose } = props;
  const [isBrowseRentalsDropdownOpen, setIsBrowseRentalsDropdownOpen] =
    useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  const { user } = useSession();
  const { disableScroll, enableScroll } = useToggleBodyScroll();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  const handleBrowseRentalsDropdown = () =>
    setIsBrowseRentalsDropdownOpen((val) => !val);

  const handleProfileDropdown = () => setIsProfileDropdownOpen((val) => !val);

  const onLinkClick = (to?: string) => {
    if (to) {
      switch (to) {
        case 'logIn':
          trackEvent(SegmentEventNamesEnum.LOGIN_BUTTON_CLICK, {
            PageName: SegmentEventPagesEnum.HOME_PAGE,
          });
          break;
        case 'signUp':
          trackEvent(SegmentEventNamesEnum.SIGNUP_BUTTON_CLICK, {
            PageName: SegmentEventPagesEnum.HOME_PAGE,
          });
          break;
        default:
          break;
      }
    }
    onClose();
  };

  return (
    <section className={styles.burgerMenu}>
      <div className={styles.burgerMenu__header}>
        <Cross
          onClick={onClose}
          stroke={colorsByTheme['dark'].textPrimaryColor}
        />
      </div>

      <div className={styles.burgerMenu__body}>
        <div
          onClick={handleBrowseRentalsDropdown}
          className={clsx([
            styles.burgerMenu__item,
            isBrowseRentalsDropdownOpen && styles.highlighted,
          ])}
        >
          Browse Rentals
          <ChevronDown stroke={colorsByTheme['dark'].textPrimaryColor} />
        </div>

        {isBrowseRentalsDropdownOpen ? (
          <section
            className={styles.burgerMenu__rentals}
            onClick={() => onLinkClick()}
          >
            {browseRentalsDropdown}
          </section>
        ) : (
          <div className={styles.burgerMenu__links}>
            <Link href="/list-on-rove">
              <a onClick={() => onLinkClick()}>Become an Owner</a>
            </Link>

            <Link href="/blog">
              <a onClick={() => onLinkClick()}>Blog</a>
            </Link>

            {user ? (
              <>
                <div
                  onClick={handleProfileDropdown}
                  className={clsx([
                    styles.burgerMenu__item,
                    styles.withBorderTop,
                    isBrowseRentalsDropdownOpen && styles.highlighted,
                  ])}
                >
                  Account
                  <ChevronDown
                    stroke={colorsByTheme['dark'].textPrimaryColor}
                  />
                </div>
                {isProfileDropdownOpen && (
                  <section
                    className={styles.burgerMenu__rentals}
                    onClick={() => onLinkClick()}
                  >
                    {profileDropdown}
                  </section>
                )}
              </>
            ) : (
              <>
                <Link href="/login">
                  <a onClick={() => onLinkClick('logIn')}>Login</a>
                </Link>
                <Link href="/sign-up">
                  <a onClick={() => onLinkClick('signUp')}>Sign Up</a>
                </Link>
              </>
            )}
          </div>
        )}
      </div>

      <div className={styles.burgerMenu__footer}>
        <a
          href={`tel:${Contacts.CALL_US}`}
          className={clsx([styles.header__phoneNumber, 'fade-animation'])}
        >
          <Cellphone fill={colorsByTheme['dark'].textPrimaryColor} />
        </a>
        <a
          className={styles.header__contactIcon}
          href={`mailto:${Contacts.CONTACT_EMAIL}`}
        >
          <Envelope fill={colorsByTheme['dark'].textPrimaryColor} />
        </a>
      </div>
    </section>
  );
};

export default BurgerMenu;
