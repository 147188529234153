export enum Global {
  MORTGAGE_LENGTH = 30,
  CLOSING_COST = 5,
  FUZZY_DATE_OFFSET_DEFAULT = 7,
}

export enum Contacts {
  CONTACT_EMAIL = 'concierge@rovetravel.com',
  FACEBOOK_URL = 'https://www.facebook.com/people/Rove-Travel/100087552227642/',
  INSTAGRAM_URL = 'https://www.instagram.com/rove_travel/',
  WHATSAPP_URL = 'https://wa.me/16468086638',
  CAREERS_SITE_URL = 'https://rovetravel.notion.site/Rove-Careers-6b20b1e95ed44000bc66eb4b56aa4931',
  CALL_US = '877-510-ROVE',
}
