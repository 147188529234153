import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Link from 'next/link';
import _isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import _omit from 'lodash/omit';

import styles from '@styles/_styles.module.scss';

import { BaseComponentProps } from '@utils/types/baseComponents';
import Header from '@components/layout/header';
import Footer from '@components/layout/footer';
import AnnouncementBanner from '@components/layout/announcementBanner';
import { GlobalSettingsType } from '@api/global/getGlobalSettings';
import { useGetGlobalSettings } from '@utils/hooks/api/useGetGlobalSettings';
import { useGetMarkets } from '@utils/hooks/api/useGetMarkets';
import { useGetActiveLandingsSlugs } from '@utils/hooks/api/useGetActiveLandingsSlugs';
import { useGetStayLengthCollections } from '@utils/hooks/api/useGetStayLengthCollections';
import {
  getMarketsFromStorage,
  setMarketsToStorage,
  getCollectionsFromStorage,
  setCollectionsToStorage,
} from '@utils/sessionStorage';

export type LayoutProps = {
  footerProps?: BaseComponentProps;
  headerProps?: { noGlassEffect?: boolean } & BaseComponentProps;
  announcementBannerProps?: BaseComponentProps;
};

const Layout = (props: PropsWithChildren<LayoutProps>) => {
  const { headerProps, footerProps, announcementBannerProps, children } = props;
  const [announcementBanner, setAnnouncementBanner] =
    useState<GlobalSettingsType['announcementBanner']>();
  const [markets, setMarkets] = useState<any>();
  const [stayLengthCollections, setStayLengthCollections] = useState<any>();

  const globalSettings = useGetGlobalSettings();
  const { refetch: refetchMarkets } = useGetMarkets(false);
  const { refetch: refetchStayLengthCollections } =
    useGetStayLengthCollections(false);
  const activeLandingsSlugs = useGetActiveLandingsSlugs();
  const { query } = useRouter();

  useEffect(() => {
    if (typeof localStorage === 'undefined') {
      return undefined;
    }

    const cachedMarkets = getMarketsFromStorage();
    const cachedStayLengthCollections = getCollectionsFromStorage();

    if (cachedMarkets) {
      setMarkets(cachedMarkets);
    } else {
      refetchMarkets().then(({ data }) => {
        setMarkets(data);
        setMarketsToStorage(data);
      });
    }

    if (cachedStayLengthCollections) {
      setStayLengthCollections(cachedStayLengthCollections);
    } else {
      refetchStayLengthCollections().then(({ data }) => {
        setStayLengthCollections(data);
        setCollectionsToStorage(data);
      });
    }
  }, []);

  useEffect(() => {
    if (!_isEmpty(globalSettings)) {
      const shouldShowAnnouncement =
        !!globalSettings.announcementBanner &&
        !!globalSettings.announcementBanner.isShown &&
        !!globalSettings.announcementBanner.text &&
        !!globalSettings.announcementBanner.text[0] &&
        !!globalSettings.announcementBanner.text[0].children;

      /** Change to true, never shown now */
      if (shouldShowAnnouncement === undefined) {
        setAnnouncementBanner(globalSettings.announcementBanner);
      }
    }
  }, [globalSettings.announcementBanner]);

  const browseRentalsLinks = useCallback(
    (type: 'search' | 'collection') => (
      <>
        {/* @ts-ignore */}
        {markets?.map(({ name, slug }) => (
          <Link
            href={{
              pathname:
                type === 'search' ? `/search` : `/collections/${slug.current}`,
              query: { ..._omit(query, ['slug']), market: slug.current },
            }}
            key={slug.current}
          >
            <a>{name}</a>
          </Link>
        ))}
        <Link href={`/search`}>
          <a>
            <p>View all on map</p>
          </a>
        </Link>
      </>
    ),
    [markets, query]
  );

  const stayLengthCollectionLinks = useMemo(
    () => (
      <>
        {/* @ts-ignore */}
        {stayLengthCollections?.map(({ name, slug }) => (
          <Link href={`/collections/${slug.current}`} key={slug.current}>
            <a>{name}</a>
          </Link>
        ))}
      </>
    ),
    [stayLengthCollections]
  );

  const propertyManagementLinks = useMemo(
    () => (
      <>
        <Link href={`/list-on-rove`}>
          <a>Become an Owner</a>
        </Link>

        <Link href={`/fee-structure`}>
          <a>Management Plans</a>
        </Link>

        <Link href={`/propertymanagement/new-york-city`}>
          <a>New York City</a>
        </Link>

        {activeLandingsSlugs?.map(({ title, slug }) => (
          <Link href={`/propertymanagement/${slug}`} key={slug}>
            <a>{title}</a>
          </Link>
        ))}
      </>
    ),
    [activeLandingsSlugs]
  );

  return (
    <>
      {!!announcementBanner && (
        <AnnouncementBanner
          {...announcementBannerProps}
          text={announcementBanner.text}
        />
      )}

      <Header
        {...headerProps}
        browseRentalsLinks={browseRentalsLinks('search')}
      />

      <main className={styles.layout__main}>{children}</main>

      <Footer
        {...footerProps}
        browseRentalsLinks={browseRentalsLinks('collection')}
        stayLengthCollectionLinks={stayLengthCollectionLinks}
        propertyManagementLinks={propertyManagementLinks}
      />
    </>
  );
};

export default Layout;
