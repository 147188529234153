import { DatesRange } from '@utils/types/calendar';
import { InvoiceType } from '@utils/types/reservationsAndStays';

export function setInvoice(invoice: InvoiceType) {
  try {
    sessionStorage.setItem('invoice', JSON.stringify(invoice));
  } catch (e) {
    console.log(`Unhandled error - ${e}`);
  }
}

export function getInvoice(): InvoiceType | undefined {
  const values = sessionStorage.getItem('invoice');

  if (values) {
    return JSON.parse(values);
  }
}

export function setMarketsToStorage(markets: any) {
  try {
    sessionStorage.setItem('markets', JSON.stringify(markets));
  } catch (e) {
    console.log(`Unhandled error - ${e}`);
  }
}

export function getMarketsFromStorage() {
  const values = sessionStorage.getItem('markets');

  if (values) {
    try {
      return JSON.parse(values);
    } catch (e) {
      console.log(`Error parsing markets from sessionStorage: ${e}`);
    }
  }

  return undefined;
}

export function setCollectionsToStorage(collections: any) {
  try {
    sessionStorage.setItem(
      'stayLengthCollections',
      JSON.stringify(collections)
    );
  } catch (e) {
    console.log(`Unhandled error - ${e}`);
  }
}

export function getCollectionsFromStorage() {
  const values = sessionStorage.getItem('stayLengthCollections');

  if (values) {
    try {
      return JSON.parse(values);
    } catch (e) {
      console.log(`Error parsing markets from sessionStorage: ${e}`);
    }
  }

  return undefined;
}
